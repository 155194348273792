import React from 'react';
import { useClearRefinements } from 'react-instantsearch-hooks-web';

export function ClearFiltersMobile({ containerRef }) {
  const { refine } = useClearRefinements();

  function onClick() {
    refine();
    document.body.classList.remove('filtering');
    containerRef.current.scrollIntoView();
  }

  return (
    <div className="ais-ClearRefinements">
      <button className="ais-ClearRefinements-button" onClick={onClick}>
        Reset filters
      </button>
    </div>
  );
}
