import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import { useEffect, useState } from 'react';
import { getIndexSearchResults } from "./getIndexSearchResults.js";
import { useIndexContext } from "./useIndexContext.js";
import { useInstantSearchContext } from "./useInstantSearchContext.js";
export function useSearchResults() {
  var search = useInstantSearchContext();
  var searchIndex = useIndexContext();

  var _useState = useState(function () {
    return getIndexSearchResults(searchIndex);
  }),
      _useState2 = _slicedToArray(_useState, 2),
      searchResults = _useState2[0],
      setSearchResults = _useState2[1];

  useEffect(function () {
    function handleRender() {
      var results = searchIndex.getResults(); // Results can be `null` when the first search is stalled.
      // In this case, we skip the update.
      // See: https://github.com/algolia/instantsearch.js/blob/20996c7a159988c58e00ff24d2d2dc98af8b980f/src/widgets/index/index.ts#L652-L657

      if (results !== null) {
        setSearchResults({
          results: results,
          scopedResults: searchIndex.getScopedResults()
        });
      }
    }

    search.addListener('render', handleRender);
    return function () {
      search.removeListener('render', handleRender);
    };
  }, [search, searchIndex]);
  return searchResults;
}